import { getTexts } from "@techweb/app/helpers/get-text";
import { mediaQueries } from "@techweb/app/helpers/media-queries";
import { useMediaQuery } from "@techweb/app/hooks/use-media-query";
import React from "react";
import { Button } from "../button";
import { S } from "./styles";

const renderParagraph = (isDesktop: boolean) =>
  getTexts().home.greenSection.paragraphs.map((paragraph) => (
    <React.Fragment key={paragraph.title}>
      {isDesktop ? (
        <S.ParagraphWrapper>
          <S.SubTitle>{paragraph.title}</S.SubTitle>
          <S.Text>{paragraph.text}</S.Text>
        </S.ParagraphWrapper>
      ) : (
        <>
          <S.SubTitle>{paragraph.title}</S.SubTitle>
          <S.Text>{paragraph.text}</S.Text>
        </>
      )}
    </React.Fragment>
  ));

export const GreenSection = () => {
  const isDesktop = useMediaQuery(mediaQueries.isDesktop);
  return (
    <S.Container>
      <S.Title>{getTexts().home.greenSection.title}</S.Title>
      {isDesktop ? (
        <S.DesktopWrapper>{renderParagraph(isDesktop)}</S.DesktopWrapper>
      ) : (
        renderParagraph(isDesktop)
      )}
      <Button onClick={() => window.location.assign("mailto:hei@techfolk.no")}>
        Send oss en e-post
      </Button>
    </S.Container>
  );
};
