import { mediaQueries } from "@techweb/app/helpers/media-queries";
import { colors } from "@techweb/styles/constants/colors";
import React, { FC } from "react";
import styled from "styled-components";

export const Paragraph: FC = ({ children }) => {
  return <S.Paragraph>{children}</S.Paragraph>;
};

const S = {
  Paragraph: styled.p`
    font-family: "Stabil Grotesk Light";
    font-size: 1.5rem;
    color: ${colors.purple};
    margin-bottom: 2rem;
    line-height: 1.18;

    @media ${mediaQueries.isDesktop} {
      font-size: 1.75rem;
    }
  `,
};
