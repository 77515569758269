import { useRef, useEffect } from 'react';

export const useDocumentTitle = (title: string, retainOnUnmount = false) => {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    const previous = defaultTitle.current;
    return () => {
      if (!retainOnUnmount) {
        document.title = previous;
      }
    };
  }, [retainOnUnmount]);
};
