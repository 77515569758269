import { mediaQueries } from "@techweb/app/helpers/media-queries";
import { colors } from "@techweb/styles/constants/colors";
import { buttonReset } from "@techweb/styles/constants/resets";
import React, { FC } from "react";
import styled from "styled-components";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  className?: string;
}

export const Button: FC<Props> = ({ className, children, ...buttonProps }) => {
  return (
    <S.Button className={className} {...buttonProps}>
      {children}
    </S.Button>
  );
};

const BUTTON_HEIGHT_MOBILE = "50px";
const BUTTON_HEIGHT_DESKTOP = "63px";

const S = {
  Button: styled.button`
    ${buttonReset}
    height: ${BUTTON_HEIGHT_MOBILE};
    max-height: ${BUTTON_HEIGHT_MOBILE};
    color: ${colors.white};
    background-color: ${colors.purple};
    font-size: 1.25rem;
    border: 2px solid transparent;
    border-bottom-left-radius: calc(${BUTTON_HEIGHT_MOBILE} / 2);
    border-bottom-right-radius: calc(${BUTTON_HEIGHT_MOBILE} / 2);
    border-top-right-radius: calc(${BUTTON_HEIGHT_MOBILE} / 2);
    margin-top: calc(2.5rem - 2rem);
    margin-bottom: 7.75rem;
    padding-left: 30px;
    padding-right: 30px;

    &:hover {
      background-color: ${colors.darkPurple};
    }

    &:active,
    &:focus {
      background-color: ${colors.purple};
      border: 2px solid ${colors.orange};
    }

    @media ${mediaQueries.isDesktop} {
      margin-top: calc(4.0625rem - 2rem);

      font-size: 1.5rem;
      height: ${BUTTON_HEIGHT_DESKTOP};
      max-height: ${BUTTON_HEIGHT_DESKTOP};
      border-bottom-left-radius: calc(${BUTTON_HEIGHT_DESKTOP} / 2);
      border-bottom-right-radius: calc(${BUTTON_HEIGHT_DESKTOP} / 2);
      border-top-right-radius: calc(${BUTTON_HEIGHT_DESKTOP} / 2);
    }
  `,
};
