import { mediaQueries } from "@techweb/app/helpers/media-queries";
import { colors } from "@techweb/styles/constants/colors";
import styled from "styled-components";

export const S = {
  TextWrapper: styled.div`
    margin-bottom: 130px;
    @media ${mediaQueries.isDesktop} {
      padding-left: calc(308px - 223px);
    }
  `,
  Header: styled.h1`
    font-size: 2.125rem;
    color: ${colors.orange};

    &:after {
      content: "";
      height: 1rem;
      background-color: ${colors.sand};
      width: 107px;
      display: block;
      margin-top: -0.5rem;
      border-bottom-left-radius: 1rem;
      border-top-right-radius: 1rem;

      @media ${mediaQueries.isDesktop} {
        width: 100%;
      }
    }

    @media ${mediaQueries.isDesktop} {
      width: 400px;
      font-size: 2.125rem;

      &:after {
        width: 309px;
      }
    }
  `,
  SubHeader: styled.h2`
    color: ${colors.purple};
    font-size: 1.5rem;
    margin-bottom: 31px;

    @media ${mediaQueries.isDesktop} {
      margin-bottom: 65px;
    }
  `,
  SandBubble: styled.div`
    background-color: ${colors.sand};
    width: 100%;
    border-bottom-left-radius: 32px;
    border-top-right-radius: 32px;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 28px;
    max-width: 439px;

    @media ${mediaQueries.isDesktop} {
      margin-bottom: 100px;
    }
  `,
  SandPurple: styled.p`
    font-family: "Stabil Grotesk Medium";
    color: ${colors.purple};
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 0.625rem;

    @media ${mediaQueries.isDesktop} {
      font-size: 1.5rem;
      margin-bottom: 0.875rem;
    }
  `,
  SandOrange: styled.p`
    color: ${colors.orange};
    font-size: 1.25rem;
    margin-top: 0;
    margin-bottom: 10px;

    @media ${mediaQueries.isDesktop} {
      font-size: 1.75rem;
      margin-bottom: 0.875rem;
    }
  `,
  SandSmall: styled.span`
    font-family: "Stabil Grotesk";
    font-size: 0.875rem;

    @media ${mediaQueries.isDesktop} {
      font-size: 1.25rem;
    }
  `,
};
