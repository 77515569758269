import { NavigationContext } from "@techweb/app/App";
import { Button } from "@techweb/app/components/button";
import { mediaQueries } from "@techweb/app/helpers/media-queries";
import { useMediaQuery } from "@techweb/app/hooks/use-media-query";
import React, { useContext } from "react";
import { S } from "./styles";

export const GreySection = () => {
  const isDesktop = useMediaQuery(mediaQueries.isDesktop);
  const { setCurrentPage } = useContext(NavigationContext);

  return (
    <S.Container>
      <S.Title>
        Vi lager smørøyet for {isDesktop && <br />} teknologi og design
      </S.Title>
      <S.Text>
        Et miljø for inspirasjon, kunnskap og vennskap – for de som elsker å
        jobbe med digitale tjenester.
      </S.Text>
      <Button onClick={() => setCurrentPage("why")}>Finn ut hvordan</Button>
      <S.Illustration />
    </S.Container>
  );
};
