import italic from './italic.woff';
import light from './light.woff';
import light_woff2 from './light.woff2';
import light_italic from './light-italic.woff';
import medium from './medium.woff';
import medium_woff2 from './medium.woff2';
import regular from './regular.woff';
import regular_woff2 from './regular.woff2';

export const LabilGrotesk = {
  italic,
  light,
  light_woff2,
  light_italic,
  medium,
  medium_woff2,
  regular,
  regular_woff2
};
