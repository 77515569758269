import { useMemo, useState, useEffect } from "react";

export const useMediaQuery = (query: string) => {
  const mql = useMemo(() => window.matchMedia(query), [query]);
  const [matches, setMatch] = useState(mql.matches);

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => setMatch(e.matches);

    try {
      mql.addEventListener("change", handler);
    } catch {
      // fallback for safari < 14
      mql.addListener(handler);
    }

    return () => {
      try {
        mql.removeEventListener("change", handler);
      } catch {
        // fallback for safari < 14
        mql.removeListener(handler);
      }
    };
  }, [mql]);

  return matches;
};
