import { NavigationContext } from "@techweb/app/App";

import { getTexts } from "@techweb/app/helpers/get-text";
import React, { useContext } from "react";
import { S } from "./styles";

export const SandSection = () => {
  const { setCurrentPage } = useContext(NavigationContext);

  return (
    <S.Container>
      <S.Title>{getTexts().home.sandSection.title}</S.Title>
      <S.Text>
        IT handler om folket. Hos oss finner du problemløsere med skarpe hoder
        og myke hjerter. Vi tar med oss alt vi er, kan og interesserer oss for
        på jobb - den beste oppskriften på magi.
      </S.Text>
      <S.Button onClick={() => setCurrentPage("about_us")}>
        Kort om oss
      </S.Button>
    </S.Container>
  );
};
