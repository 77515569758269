import React from "react";
import ReactDOM from "react-dom";
import { createGlobalStyle } from "styled-components";
import { App } from "./app/App";
import { Normalize } from "styled-normalize";
import { fontFaces } from "./styles/constants/fonts";
import reportWebVitals from "./reportWebVitals";

const GlobalStyles = createGlobalStyle`
    ${fontFaces}



    h1 {
      margin: 0;
    }

    html, body {
      overflow-x: hidden;
      font-family: 'Stabil Grotesk';
      width: 100%;
    }
`;

ReactDOM.render(
  <>
    <GlobalStyles />
    <Normalize />
    <App />
  </>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
