import React, { FC } from "react";
import styled from "styled-components";

export const MaxWidthWrapper: FC = ({ children }) => {
  return <S.MaxWidthWrapper>{children}</S.MaxWidthWrapper>;
};

const S = {
  MaxWidthWrapper: styled.div`
    max-width: 812px;
    margin: 0 auto;
  `,
};
