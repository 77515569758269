import React, { FC } from "react";
import styled from "styled-components";

export const Labil: FC = ({ children }) => {
  return <S.Labil>{children}</S.Labil>;
};

const S = {
  Labil: styled.span`
    font-family: "Labil Grotesk";
  `,
};
