import { getTexts } from "@techweb/app/helpers/get-text";
import React from "react";
import { S } from "./styles";

export const Header = () => {
  return (
    <S.Container>
      <S.Logo />
      <S.TitleWrapper>{getTexts().home.header.title}</S.TitleWrapper>
      <S.Illustration />
    </S.Container>
  );
};
