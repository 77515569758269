import React, { FC } from "react";
import styled from "styled-components";
import { colors } from "../../../styles/constants/colors";
import { mediaQueries } from "../../helpers/media-queries";

export const Title: FC = ({ children }) => {
  return <S.Title>{children}</S.Title>;
};

const S = {
  Title: styled.h1`
    margin-top: 32px;
    margin-bottom: 0.5rem;
    font-family: "Stabil Grotesk";
    font-size: 2.625rem;
    color: ${colors.purple};

    @media ${mediaQueries.isDesktop} {
      font-size: 3.375rem;
    }
  `,
};
