import React, { Dispatch, SetStateAction, useState } from "react";
import { useDocumentTitle } from "./hooks/use-document-title";
import { Home } from "./pages/home";
import { AboutUs } from "./pages/about_us";
import { assertNever } from "./helpers/assert-never";
import { Footer } from "./components/footer";
import { Why } from "./pages/why";

type Pages = "home" | "about_us" | "why";

type Navigation = {
  currentPage: Pages;
  setCurrentPage: Dispatch<SetStateAction<Pages>>;
};

export const MenuContext = React.createContext({
  menuOpen: false,
  toggleMenu: () => {},
});
export const NavigationContext = React.createContext<Navigation>({
  currentPage: "home",
  setCurrentPage: () => null,
});

export const App = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState<Pages>("home");

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const getDocumentTitle = () => {
    switch (currentPage) {
      case "home":
        return "Techfolk";
      case "about_us":
        return "Techfolk - Om oss";
      case "why":
        return "Techfolk - Hvorfor";
      default:
        assertNever(currentPage);
        return "";
    }
  };

  useDocumentTitle(getDocumentTitle());

  const renderRoute = () => {
    switch (currentPage) {
      case "home":
        return <Home />;
      case "about_us":
        return <AboutUs />;
      case "why":
        return <Why />;
      default:
        assertNever(currentPage);
        return null;
    }
  };

  return (
    <NavigationContext.Provider value={{ currentPage, setCurrentPage }}>
      <MenuContext.Provider value={{ menuOpen, toggleMenu }}>
        {renderRoute()}
        <Footer />
      </MenuContext.Provider>
    </NavigationContext.Provider>
  );
};
