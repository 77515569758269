export const mediaQueries = {
  isSmallMobilePortrait:
    "(max-width: 320px) and (max-height: 568px) and (orientation: portrait)",
  isMobileLandscape:
    "(max-width: 926px) and (max-height: 428px) and (orientation: landscape)",
  isMobilePortrait: "(max-width: 432px)",
  isTabletPortrait: "(max-width: 1024px)",
  isTabletLandscape:
    "(max-width: 1024px) and (min-height: 768px) and (orientation: landscape)",
  isDesktop: "(min-width: 1024px)",
  isLargeDesktop: "(min-width: 1920px)",
};
