import { mediaQueries } from "@techweb/app/helpers/media-queries";
import { colors } from "@techweb/styles/constants/colors";
import { buttonReset } from "@techweb/styles/constants/resets";
import styled, { css } from "styled-components";
import { BrandLogo } from "../logo";

export const S = {
  Container: styled.div`
    background-color: ${colors.purple};
    border-top-right-radius: 25vw;
    min-height: 400px;
    color: ${colors.white};
    padding-bottom: 32px;

    @media ${mediaQueries.isDesktop} {
      padding-left: 138px;
      padding-right: 223px;
      border-top-right-radius: 128px;
    }
  `,
  BrandLogo: styled(BrandLogo)`
    width: 164px;
    margin-top: 95px;
    margin-left: 30px;
  `,
  TextPositioning: styled.div`
    margin-top: 6rem;
    margin-left: 30px;
  `,
  Hello: styled.p`
    font-family: "Stabil Grotesk Medium";
    font-size: 1.75rem;
    margin-top: 76px;

    @media ${mediaQueries.isDesktop} {
      font-size: 2rem;
    }
  `,
  InfoWrapper: styled.div`
    display: flex;
    flex-direction: column;

    @media ${mediaQueries.isDesktop} {
      flex-direction: row;
    }
  `,
  InfoEntry: styled.div`
    flex: 0 1 auto;

    @media ${mediaQueries.isDesktop} {
      margin-right: 78px;
    }
  `,
  TitleText: styled.p`
    font-family: "Stabil Grotesk Medium";
    font-size: 1.5rem;
    margin-top: 4.75rem;
    padding: 0 10px;
    margin-left: -10px;
    white-space: nowrap;
  `,
  NormalText: styled.p`
    font-family: "Stabil Grotesk Light";
    font-size: 1.5rem;
    margin-top: 0.5rem;
    border: 2px solid transparent;
    padding: 10px;
    margin-left: -10px;
    white-space: nowrap;
  `,
  EmailLink: styled.a`
    display: inline-block;
    font-family: "Stabil Grotesk Light";
    font-size: 1.5rem;
    color: white;
    text-decoration: none;
    border: 2px solid transparent;
    padding: 10px;
    margin-left: -10px;

    &:focus {
      outline: none;
      border: 2px solid ${colors.white};
      border-top-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }

    &:hover {
      border: 2px solid transparent;
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 4px;
        border-top-left-radius: 4px;
        margin-top: 4px;
        border-bottom-right-radius: 4px;
        background-color: ${colors.white};
      }
    }
  `,
  SocialWrapper: styled.div`
    margin-top: 4.1875rem;
  `,
  SocialTitle: styled.p`
    font-size: 1.375rem;
    line-height: 1.64;
    font-family: "Stabil Grotesk Light";
  `,
  SocialButton: styled.button<{ reversed?: boolean }>`
    ${({ reversed }) => css`
      ${buttonReset}
      width: 62px;
      height: 62px;
      background-color: ${colors.darkPurple};
      margin-right: 16px;

      ${reversed
        ? css`
            border-top-left-radius: calc(62px / 3);
            border-bottom-right-radius: calc(62px / 3);
          `
        : css`
            border-top-right-radius: calc(62px / 3);
            border-bottom-left-radius: calc(62px / 3);
          `}

      &:active,
      &:focus {
        border: 2px solid ${colors.orange};
      }
    `}
  `,
  InfoTextWrapper: styled.div`
    margin-top: 5.6875rem;
    font-size: 1.125rem;
    font-family: "Stabil Grotesk";
    margin-bottom: 12.1875rem;
  `,
  BrandName: styled.p`
    color: ${colors.orange};
    margin: 0;
  `,
  InfoText: styled.p`
    margin: 0;
  `,
};
