import {
  BackButton,
  GreenSection,
  MaxWidthWrapper,
  PagePadding,
  Paragraph,
  Title,
} from "@techweb/app/components";
import { Labil } from "@techweb/app/components/typography/labil";
import { mediaQueries } from "@techweb/app/helpers/media-queries";
import { useMediaQuery } from "@techweb/app/hooks/use-media-query";
import React, { useEffect } from "react";
import { S } from "./styles";

export const Why = () => {
  const isDesktop = useMediaQuery(mediaQueries.isDesktop);
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <PagePadding bottomPaddingDesktop={170}>
        <MaxWidthWrapper>
          <BackButton />
          <S.Header>Vi lager smørøyet for teknologi og design</S.Header>
          <S.SubHeader>Hvordan gjør vi det?</S.SubHeader>
          <S.SandBubble>
            <S.SandPurple>UTTRYKK</S.SandPurple>
            <S.SandOrange>Midt i smørøyet</S.SandOrange>
            <S.SandPurple>
              {"overført".toUpperCase()}{" "}
              <S.SandSmall>
                i det mest interessante, sentrale, beste området
              </S.SandSmall>
            </S.SandPurple>
          </S.SandBubble>
          <S.TextWrapper>
            <Title>
              Drive <Labil>digital</Labil> innovasjon
            </Title>
            <Paragraph>
              Vi skal samarbeide med visjonærene om å drive den digitale
              revolusjonen. Kundene våre skal være inkluderende, læreglade og ha
              store ambisjoner. Dette tillater oss å operere i fremkant, utnytte
              det fulle potensialet vårt og levere verdi med kollektiv kraft.
            </Paragraph>
            <Title>
              Folk som <Labil>liker</Labil> <br />å bli utfordret
            </Title>
            <Paragraph>
              Vi skal omgi oss med flinke folk det er moro å jobbe med –
              vaskeekte problemløsere som får (og gir) energi av å bli
              utfordret. Sammen utgjør vi et fremoverlent miljø som stadig
              flytter grenser. Er det lett er det kjedelig, er det vanskelig er
              det gøy!
            </Paragraph>
            <Title>
              Gjøre det {!isDesktop && <br />} <Labil>enkelt</Labil>{" "}
              {isDesktop && <br />}å være konsulent
            </Title>
            <Paragraph>
              I Techfolk skal vi gjøre det befriende enkelt å fokusere på
              meningsfulle oppgaver, lære nye ting og utvikle seg i et høyt
              tempo. Vi har minst mulig regler – vi stoler på at naturlige
              mekanismer gir fart til verdifulle idéer og bremser dårlige. Dette
              tillater oss å være ubegrenset, i kontinuerlig forbedring og
              stadig relevant overfor oss selv og kundene.
            </Paragraph>
          </S.TextWrapper>
        </MaxWidthWrapper>
      </PagePadding>
      <GreenSection />
    </>
  );
};
