import { mediaQueries } from "@techweb/app/helpers/media-queries";
import { colors } from "@techweb/styles/constants/colors";
import styled from "styled-components";

export const S = {
  Container: styled.div`
    background-color: ${colors.green};
    padding-left: 1.875rem;
    padding-right: 2.5rem;
    border-top-left-radius: 4rem;
    color: ${colors.white};
    padding-bottom: 50vh;
    margin-bottom: -50vh;

    @media ${mediaQueries.isTabletPortrait} {
      padding-left: calc(1.875rem * 1.5);
      padding-right: calc(2.5rem * 1.5);
    }

    @media ${mediaQueries.isDesktop} {
      padding-left: 13.9375rem;
      padding-right: 18.25rem;
    }
  `,
  Title: styled.h1`
    font-size: 2.125rem;
    padding-top: 4.75rem;

    @media ${mediaQueries.isDesktop} {
      font-size: 3.375rem;
      padding-top: 6.25rem;
    }
  `,
  DesktopWrapper: styled.div`
    display: flex;
  `,
  ParagraphWrapper: styled.div`
    flex: 1;
    margin-right: 5.3125rem;
  `,
  SubTitle: styled.h2`
    margin-top: 2.5rem;
    font-size: 1.5rem;

    @media ${mediaQueries.isDesktop} {
      font-size: 1.75rem;
    }
  `,
  Text: styled.p`
    font-family: "Stabil Grotesk Light";
    font-size: 1.25rem;
    margin-bottom: 2rem;

    @media ${mediaQueries.isDesktop} {
      font-size: 1.625rem;
    }
  `,
};
