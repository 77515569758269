import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as Logo } from "@techweb/assets/svg/logo/logo.svg";

interface Props {
  color: string;
  className?: string;
}

export const BrandLogo = ({ color, className }: Props) => {
  return <S.BrandLogo color={color} className={className} />;
};

const S = {
  BrandLogo: styled(Logo)<{ color: string }>`
    ${({ color }) => css`
      > g > path,
      > g > rect {
        fill: ${color};
      }
    `}
  `,
};
