import React from "react";
import { Header } from "./header";
import { SandSection } from "./sand_section";
import { WhiteSection } from "./white_section";
import { GreySection } from "./grey_section";
import { GreenSection } from "@techweb/app/components";

export const Home = () => {
  return (
    <>
      <Header />
      <SandSection />
      <WhiteSection />
      <GreySection />
      <GreenSection />
    </>
  );
};
