import { Button } from "@techweb/app/components/button";
import { mediaQueries } from "@techweb/app/helpers/media-queries";
import { colors } from "@techweb/styles/constants/colors";
import styled from "styled-components";

export const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${colors.sand};
    padding-left: 1.875rem;
    padding-right: 2.5rem;
    border-bottom-right-radius: 25vw;

    @media ${mediaQueries.isTabletPortrait} {
      height: inherit;
    }

    @media ${mediaQueries.isDesktop} {
      height: inherit;
      padding-left: 13.9375rem;
      padding-right: 19.25rem;
    }
  `,
  Title: styled.h1`
    color: ${colors.purple};
    font-size: 2.125rem;
    padding-top: 4.75rem;

    @media ${mediaQueries.isDesktop} {
      padding-top: 6rem;
      font-size: 3.375rem;
    }
  `,
  Text: styled.p`
    font-family: "Stabil Grotesk Light";
    font-size: 1.5rem;
    color: ${colors.purple};
    margin-bottom: 2rem;
    flex: 1 0 auto;
    @media ${mediaQueries.isDesktop} {
      font-size: 1.75rem;
      width: 749px;
    }
  `,
  Button: styled(Button)`
    width: fit-content;
  `,
};
