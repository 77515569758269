import { css } from 'styled-components';

export const buttonReset = css`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  outline: none;

  color: inherit;
  font: inherit;
  cursor: pointer;

  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  appearance: none;
`;
