import { css } from "styled-components";
import { LabilGrotesk } from "../../assets/fonts/LabilGrotesk";
import { StabilGrotesk } from "../../assets/fonts/StabilGrotesk";

export const fontFaces = css`
  @font-face {
    font-family: "Labil Grotesk Italic";
    font-display: swap;
    src: url(${LabilGrotesk.italic}) format("woff");
  }

  @font-face {
    font-family: "Labil Grotesk Light";
    font-display: swap;
    src: url(${LabilGrotesk.light_woff2}) format("woff2"),
      url(${LabilGrotesk.light}) format("woff");
  }

  @font-face {
    font-family: "Labil Grotesk Light Italic";
    font-display: swap;
    src: url(${LabilGrotesk.light_italic}) format("woff2");
  }

  @font-face {
    font-family: "Labil Grotesk Medium";
    font-display: swap;
    src: url(${LabilGrotesk.medium_woff2}) format("woff2"),
      url(${LabilGrotesk.medium}) format("woff");
  }

  @font-face {
    font-family: "Labil Grotesk";
    font-display: swap;
    src: url(${LabilGrotesk.regular_woff2}) format("woff2"),
      url(${LabilGrotesk.regular}) format("woff");
  }

  @font-face {
    font-family: "Stabil Grotesk Italic";
    font-display: swap;
    src: url(${StabilGrotesk.italic}) format("woff");
  }

  @font-face {
    font-family: "Stabil Grotesk Light";
    font-display: swap;
    src: url(${StabilGrotesk.light_woff2}) format("woff2"),
      url(${StabilGrotesk.light}) format("woff");
  }

  @font-face {
    font-family: "Stabil Grotesk Light Italic";
    font-display: swap;
    src: url(${StabilGrotesk.light_italic}) format("woff2");
  }

  @font-face {
    font-family: "Stabil Grotesk Medium";
    font-display: swap;
    src: url(${StabilGrotesk.medium_woff2}) format("woff2"),
      url(${StabilGrotesk.medium}) format("woff");
  }

  @font-face {
    font-family: "Stabil Grotesk";
    font-display: swap;
    src: url(${StabilGrotesk.regular_woff2}) format("woff2"),
      url(${StabilGrotesk.regular}) format("woff");
  }
`;
