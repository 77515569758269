import { mediaQueries } from "@techweb/app/helpers/media-queries";
import React, { FC } from "react";
import styled, { css } from "styled-components";

interface Props {
  bottomPaddingDesktop: number;
}

export const PagePadding: FC<Props> = ({ children, bottomPaddingDesktop }) => {
  return (
    <S.PagePadding bottomPaddingDesktop={bottomPaddingDesktop}>
      {children}
    </S.PagePadding>
  );
};

const S = {
  PagePadding: styled.div<Props>`
    ${({ bottomPaddingDesktop }) => css`
      position: relative;
      padding-left: 30px;
      padding-right: 40px;

      @media ${mediaQueries.isDesktop} {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: ${bottomPaddingDesktop}px;
        margin: 0 auto;
      }
    `}
  `,
};
