import { mediaQueries } from "@techweb/app/helpers/media-queries";
import { colors } from "@techweb/styles/constants/colors";
import styled from "styled-components";

export const S = {
  Section: styled.section`
    padding-left: 1.875rem;
    padding-right: 2.5rem;
    padding-bottom: 5rem;

    @media ${mediaQueries.isDesktop} {
      padding-left: 19.25rem;
      padding-right: 19.25rem;
      padding-bottom: 8rem;
    }
  `,
  Header: styled.h2`
    margin: 6.5625rem auto 0;
    font-size: 1.25rem;
    color: ${colors.orange};
    width: 314px;
    font-weight: 500;

    @media ${mediaQueries.isSmallMobilePortrait} {
      font-size: 1rem;
      width: 250px;
    }

    @media ${mediaQueries.isDesktop} {
      font-size: 1.875rem;
      width: 470px;
    }

    &:after {
      content: "";
      height: calc(1.25rem / 2);
      background-color: ${colors.sand};
      width: 100%;
      display: block;
      border-bottom-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
  `,
  VisionaryHeader: styled.h2`
    margin: 6.5625rem auto 0;
    font-size: 1.25rem;
    color: ${colors.orange};
    font-weight: 500;
    width: 190px;

    @media ${mediaQueries.isMobilePortrait} {
      position: relative;
      text-align: center;
    }
    @media ${mediaQueries.isSmallMobilePortrait} {
      font-size: 1rem;
    }

    @media ${mediaQueries.isTabletPortrait} {
      width: 316px;
    }

    @media ${mediaQueries.isDesktop} {
      font-size: 1.875rem;
      width: 474px;
    }

    &:after {
      content: "";
      height: calc(1.25rem / 2);
      background-color: ${colors.sand};
      width: 100%;
      display: block;
      border-bottom-left-radius: 1rem;
      border-top-right-radius: 1rem;

      @media ${mediaQueries.isMobilePortrait} {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 150px;
      }
    }
  `,
  Container: styled.div`
    background-color: ${colors.white};
  `,
  Title: styled.h1`
    color: ${colors.purple};
    font-size: 2.125rem;
    margin-top: calc(25vw / 2);
    margin-bottom: 1rem;

    @media ${mediaQueries.isDesktop} {
      font-size: 3.375rem;
      margin-top: 68px;
    }
  `,
  Text: styled.p`
    font-family: "Stabil Grotesk Light";
    font-size: 1.5rem;
    color: ${colors.purple};
    margin-bottom: 2rem;

    @media ${mediaQueries.isDesktop} {
      font-size: 1.75rem;
      line-height: 1.18;
    }
  `,
  PartnerImageSection: styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 32px;
    place-items: center;
    max-width: 1024px;
    margin: 0 auto 150px;

    @media (max-width: 600px) {
      margin-bottom: 90px;
    }

    @media ${mediaQueries.isTabletPortrait} {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 700px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 0;

      img {
        max-height: 106px;
      }
    }
  `,
  PartnerImage: styled.img`
    display: block;
  `,
};
