import { colors } from "@techweb/styles/constants/colors";
import styled from "styled-components";
import { ReactComponent as Illustration } from "@techweb/assets/svg/illustrations/buttertech.svg";
import { mediaQueries } from "@techweb/app/helpers/media-queries";

export const S = {
  TopItemsWrapper: styled.div`
    @media ${mediaQueries.isDesktop} {
      margin-bottom: 64px;
      margin-left: 223px;
    }
  `,
  Header: styled.h1`
    font-size: 2.125rem;
    color: ${colors.orange};

    &:after {
      content: "";
      height: 1rem;
      background-color: ${colors.sand};
      width: 7.375rem;
      display: block;
      margin-top: -0.5rem;
      border-bottom-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
  `,
  SubHeader: styled.p`
    color: ${colors.purple};
    font-size: 6.5vmin;
  `,
  Illustration: styled(Illustration)`
    display: none;

    @media ${mediaQueries.isDesktop} {
      display: block;
      position: absolute;
      bottom: -24px;
      right: 0;
    }
  `,
};
