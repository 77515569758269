export const colors = {
  white: '#fff',
  purple: '#5c39b6',
  darkPurple: '#3b1a8e',
  orange: '#ff704c',
  green: '#00c68a',
  sand: '#ffe8cd',
  grey: '#f4f4f4',
  shadow50: 'rgba(0,0,0,0.5)',
  shadow20: 'rgba(0,0,0,0.20)'
};
