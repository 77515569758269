import styled from "styled-components";
import { ReactComponent as Illustration } from "@techweb/assets/svg/illustrations/buttereye.svg";
import { colors } from "@techweb/styles/constants/colors";
import { mediaQueries } from "@techweb/app/helpers/media-queries";

export const S = {
  Container: styled.div`
    background-color: ${colors.grey};
    padding-left: 1.875rem;
    padding-right: 2.5rem;
    padding-bottom: 15.0625rem;
    position: relative;

    @media ${mediaQueries.isDesktop} {
      height: inherit;
      padding-left: 13.9375rem;
      padding-right: 24.5625rem;
      padding-bottom: 5rem;
    }

    &:after {
      content: "";
      display: block;
      height: 6rem;
      background-color: ${colors.grey};
      width: 25vw;
      position: absolute;
      bottom: -6rem;
      left: 0;
      z-index: -1;
    }
  `,
  Title: styled.h1`
    color: ${colors.purple};
    font-size: 2.625rem;
    padding-top: 4.6875rem;
    padding-bottom: 1rem;
    width: 290px;

    @media ${mediaQueries.isDesktop} {
      font-size: 3.375rem;
      width: 828px;
    }
  `,
  Text: styled.p`
    font-family: "Stabil Grotesk Light";
    font-size: 24px;
    color: ${colors.purple};
    margin-bottom: 2rem;

    @media ${mediaQueries.isDesktop} {
      font-size: 1.75rem;
      max-width: 579px;
    }
  `,
  Illustration: styled(Illustration)`
    height: 15.0625rem;
    width: auto;
    position: absolute;
    right: 0;
    bottom: -24px;

    @media ${mediaQueries.isDesktop} {
      height: 18.75rem;
      right: 138px;
    }
  `,
};
