import { NavigationContext } from "@techweb/app/App";
import { mediaQueries } from "@techweb/app/helpers/media-queries";
import { colors } from "@techweb/styles/constants/colors";
import React, { useContext } from "react";
import styled from "styled-components";
import { ReactComponent as NavBackIcon } from "@techweb/assets/svg/icon/nav_back.svg";

export const BackButton = () => {
  const { setCurrentPage } = useContext(NavigationContext);

  return (
    <S.Back role="button" tabIndex={0} onClick={() => setCurrentPage("home")}>
      <S.NavBackIcon /> <S.Text>Tilbake</S.Text>
    </S.Back>
  );
};

const S = {
  Back: styled.div`
    display: flex;
    align-items: center;
    margin-top: 4.0625rem;
    margin-bottom: 3.4375rem;
    font-size: 1.5rem;
    color: ${colors.orange};
    cursor: pointer;
    width: fit-content;
    border: 2px solid transparent;
    padding: 10px;

    &:focus {
      outline: none;
      border: 2px solid ${colors.purple};
      border-top-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }

    @media ${mediaQueries.isDesktop} {
      margin-top: 6.3125rem;
      margin-bottom: 6.25rem;
    }
  `,
  Text: styled.span`
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 4px;
      background-color: transparent;
    }
    &:hover {
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 4px;
        border-top-left-radius: 4px;
        border-bottom-right-radius: 4px;
        background-color: ${colors.orange};
      }
    }
  `,
  NavBackIcon: styled(NavBackIcon)`
    margin-right: 0.875rem;
  `,
};
