import React from "react";
import { S } from "./styles";
import { ReactComponent as LinkedInIcon } from "@techweb/assets/svg/icon/linkedin.svg";
import { ReactComponent as InstagramIcon } from "@techweb/assets/svg/icon/instagram.svg";
import { getTexts } from "@techweb/app/helpers/get-text";
import { colors } from "@techweb/styles/constants/colors";

export const Footer = () => {
  return (
    <S.Container>
      <S.BrandLogo color={colors.white} />

      <S.TextPositioning>
        <S.Hello id="footer-section">
          {getTexts().footer.title}
          <span role="img" aria-label="waving emoji">
            👋
          </span>
        </S.Hello>
        <S.InfoWrapper>
          <S.InfoEntry>
            <S.TitleText>{getTexts().footer.phone.label}</S.TitleText>
            <S.NormalText>{getTexts().footer.phone.value}</S.NormalText>
          </S.InfoEntry>
          <S.InfoEntry>
            <S.TitleText>{getTexts().footer.email.label}</S.TitleText>
            <S.EmailLink href="mailto:hei@techfolk.no">
              {getTexts().footer.email.value}
            </S.EmailLink>
          </S.InfoEntry>
          <S.InfoEntry>
            <S.TitleText>{getTexts().footer.address.label}</S.TitleText>
            <S.NormalText>{getTexts().footer.address.value}</S.NormalText>
          </S.InfoEntry>
        </S.InfoWrapper>
        <S.SocialWrapper>
          <S.SocialTitle>Bli bedre kjent med oss på</S.SocialTitle>
          <S.SocialButton
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/techfolk/",
                "_blank"
              )
            }
          >
            <LinkedInIcon />
          </S.SocialButton>
          <S.SocialButton
            reversed={true}
            onClick={() =>
              window.open("https://www.instagram.com/techfolk.insta", "_blank")
            }
          >
            <InstagramIcon />
          </S.SocialButton>
        </S.SocialWrapper>
        <S.InfoTextWrapper>
          <S.BrandName>{getTexts().footer.companyInfo.name}</S.BrandName>
          <S.InfoText>{getTexts().footer.companyInfo.orgnr}</S.InfoText>
          <S.InfoText>{getTexts().footer.companyInfo.postAddress}</S.InfoText>
        </S.InfoTextWrapper>
      </S.TextPositioning>
    </S.Container>
  );
};
