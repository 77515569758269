import styled from "styled-components";
import { ReactComponent as Logo } from "@techweb/assets/svg/logo/logo.svg";
import { ReactComponent as Illustration } from "@techweb/assets/svg/illustrations/together.svg";
import { colors } from "@techweb/styles/constants/colors";
import { mediaQueries } from "@techweb/app/helpers/media-queries";

export const S = {
  Container: styled.div`
    width: 100vw;
    height: 100vh;
    background-color: ${colors.purple};
    color: ${colors.white};
    overflow: hidden;
    box-sizing: border-box;

    @media ${mediaQueries.isDesktop} {
      padding-left: 8.5rem;
    }
  `,
  Logo: styled(Logo)`
    width: 40vmin;
    height: auto;
    margin-top: 6.25rem;
    margin-left: 30px;

    @media ${mediaQueries.isMobileLandscape} {
      margin-top: 1.5rem;
    }

    @media ${mediaQueries.isDesktop} {
      width: 30vmin;
      margin-left: 0;
    }

    > g > path,
    > g > rect {
      fill: ${colors.orange};
    }
  `,
  TitleWrapper: styled.div`
    width: 77%;
    max-width: 375px;
    margin-top: 94px;
    margin-left: 30px;
    font-size: 2.625rem;
    line-height: 1.25;
    font-family: "Stabil Grotesk Medium";

    @media ${mediaQueries.isMobileLandscape} {
      margin-top: 32px;
    }

    @media ${mediaQueries.isMobilePortrait} {
      margin-top: 44px;
    }

    @media ${mediaQueries.isDesktop} {
      font-size: 4.625rem;
      margin-top: 142px;
      max-width: 834px;
      margin-left: 0;
    }
  `,
  Illustration: styled(Illustration)`
    position: absolute;
    bottom: -8px;
    width: 280px;
    height: auto;

    @media ${mediaQueries.isSmallMobilePortrait} {
      width: 210px;
    }

    @media ${mediaQueries.isMobileLandscape} {
      width: 12.5rem;
      right: 64px;
    }

    @media ${mediaQueries.isDesktop} {
      right: 8.625rem;
      width: 390px;
    }

    @media ${mediaQueries.isLargeDesktop} {
      width: 520px;
    }
  `,
};
