import { MaxWidthWrapper } from "@techweb/app/components";
import { getTexts } from "@techweb/app/helpers/get-text";
import React from "react";
import { S } from "./styles";
import finnLogo from "@techweb/assets/svg/logo/partner_logo_finn.svg";
import kahootLogo from "@techweb/assets/svg/logo/partner_logo_kahoot.svg";
import pexipLogo from "@techweb/assets/svg/logo/partner_logo_pexip.svg";
import ruterLogo from "@techweb/assets/svg/logo/partner_logo_ruter.svg";
import fleksLogo from "@techweb/assets/svg/logo/partner_logo_fleks.svg";
import strimLogo from "@techweb/assets/svg/logo/partner_logo_strim.svg";
import boligmappaLogo from "@techweb/assets/svg/logo/partner_logo_boligmappa.svg";
import vippsLogo from "@techweb/assets/svg/logo/partner_logo_vipps.svg";
import mimiroLogo from "@techweb/assets/svg/logo/partner_logo_mimiro.svg";
import goodtechLogo from "@techweb/assets/svg/logo/partner_logo_goodtech.svg";
import koerLogo from "@techweb/assets/svg/logo/partner_logo_koer.svg";
import elhubLogo from "@techweb/assets/svg/logo/partner_logo_elhub.svg";

export const WhiteSection = () => {
  return (
    <>
      <S.Section>
        <MaxWidthWrapper>
          <S.Header>Vi gjør det enklere å lykkes digitalt</S.Header>
          {getTexts().home.whiteSections.map(({ title, text }, i) => (
            <S.Container key={i}>
              <S.Title>{title}</S.Title>
              <S.Text>{text}</S.Text>
            </S.Container>
          ))}
          <S.VisionaryHeader>
            Noen av visjonærene vi jobber med
          </S.VisionaryHeader>
        </MaxWidthWrapper>
      </S.Section>
      <S.PartnerImageSection>
        <S.PartnerImage src={finnLogo} alt="Finn logo" />
        <S.PartnerImage src={pexipLogo} alt="Pexip logo" />
        <S.PartnerImage src={ruterLogo} alt="Ruter logo" />
        <S.PartnerImage src={kahootLogo} alt="Kahoot logo" />
        <S.PartnerImage src={fleksLogo} alt="Fleks logo" />
        <S.PartnerImage src={strimLogo} alt="Strim logo" />
        <S.PartnerImage src={vippsLogo} alt="Vipps logo" />
        <S.PartnerImage src={boligmappaLogo} alt="Boligmappa logo" />
        <S.PartnerImage src={mimiroLogo} alt="Mimiro logo" />
        <S.PartnerImage src={goodtechLogo} alt="Goodtech logo" />
        <S.PartnerImage src={koerLogo} alt="Kör logo" />
        <S.PartnerImage src={elhubLogo} alt="Elhub logo" />
      </S.PartnerImageSection>
    </>
  );
};
