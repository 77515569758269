import {
  BackButton,
  GreenSection,
  MaxWidthWrapper,
  PagePadding,
  Paragraph,
} from "@techweb/app/components";
import { Title } from "@techweb/app/components/typography/title";
import React, { useEffect } from "react";
import { S } from "./styles";

export const AboutUs = () => {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <PagePadding bottomPaddingDesktop={449}>
        <S.TopItemsWrapper>
          <BackButton />
          <S.Header>Om oss</S.Header>
        </S.TopItemsWrapper>
        <MaxWidthWrapper>
          <Title>
            Techfolk er her for å hjelpe visjonærene med det digitale
          </Title>

          <Paragraph>
            Techfolk er et IT-konsulentselskap med masse erfaring fra ledende
            teknologimiljøer i Oslo. Store og små, offentlige og private.
            <br />
            <br />
            Gjennom kompetanse på teknologi og design gjør vi det enklere å
            lykkes digitalt. Vi er fremoverlent og menneskelig, slik vi mener et
            konsulentselskap skal være.
            <br />
            <br />
            For å komme hit har vi samlet dyktige folk som liker å bli
            utfordret, og som vil hjelpe andre med å realisere ambisjonene sine
            på det digitale. Vi jobber med noen av de største visjonærene og
            flere skal det bli.
            <br />
            <br />
            Drømmen vår er å lage smørøyet for digital teknologi - et miljø fylt
            av inspirasjon, kunnskap og vennskap for oss selv og de vi jobber
            med.
          </Paragraph>
        </MaxWidthWrapper>
        <S.Illustration />
      </PagePadding>
      <GreenSection />
    </>
  );
};
